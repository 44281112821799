import { UUID } from 'node:crypto';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@core/services/environment.service';
import { Language } from '@kerberos-compliance/lib-adp-shared/localization/language';
import { WorkItemStatus } from '@kerberos-compliance/lib-adp-shared/work-items/enums/work-item.enum';
import { CreateDocumentBodyDto, CreateMessageDto, CreateWorkItemDto } from '@kyc/types/kyc.dto';
import {
  CommunicationThread,
  DataReference,
  Message,
  PaginatedResponse,
  SearchWorkItem,
  WorkItem,
  WorkItemFormData,
  WorkItemFormFieldConfigResponse,
  WorkItemType,
} from '@kyc/types/kyc.types';
import { map, Observable } from 'rxjs';
import { compareByKey } from '@/shared/pipes/sort-data.pipe';

@Injectable({ providedIn: 'root' })
export class KycService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly environmentService: EnvironmentService,
  ) {}

  /**
   * Retrieves a work item by its ID.
   *
   * @param {UUID} workItemId - The ID of the work item to be retrieved.
   * @return {Observable<WorkItem>} - An observable that emits the workitem.
   */
  public getWorkItemById(workItemId: UUID): Observable<WorkItem> {
    return this.httpClient.get<WorkItem>(`${this.environmentService.baseAdpHost}/work-item/details/${workItemId}`, {
      withCredentials: true,
    });
  }

  /**
   * Downloads the files associated with the specified work item as a ZIP archive.
   *
   * @param {UUID} workItemId - The unique identifier of the work item whose files are to be downloaded.
   * @return {Observable<HttpResponse<Blob>>} - An observable that emits the HTTP response containing the ZIP file as a Blob.
   */
  public downloadWorkItemFiles(workItemId: UUID): Observable<HttpResponse<Blob>> {
    return this.httpClient.get(`${this.environmentService.baseAdpHost}/work-item/data/files/${workItemId}`, {
      withCredentials: true,
      responseType: 'blob',
      observe: 'response',
    });
  }

  /**
   * Marks a work item as viewed by making a POST request to the server.
   *
   * @param {UUID} workItemId - The unique identifier of the work item to mark as viewed.
   * @return {Observable<WorkItem>} An Observable that emits the marked work item.
   */
  public workItemHasBeenViewed(workItemId: UUID): Observable<WorkItem> {
    return this.httpClient.post<WorkItem>(
      `${this.environmentService.baseAdpHost}/workflow/work-item/${workItemId}/actions/mark-as-read`,
      {
        withCredentials: true,
      },
    );
  }

  /**
   * Creates a new work item with the specified type and display name.
   *
   * @param {number} workItemTypeId - The ID representing the type of work item to create.
   * @param {string} displayName - The display name for the new work item.
   * @return {Observable<WorkItem>} An Observable that will emit the created WorkItem object.
   */
  public createWorkItem(workItemTypeId: number, displayName: string): Observable<WorkItem> {
    const body: CreateWorkItemDto = {
      workItemTypeId: workItemTypeId,
      status: WorkItemStatus.ActionInternal,
      displayName,
    };
    return this.httpClient.post<WorkItem>(`${this.environmentService.baseAdpHost}/work-item`, body, {
      withCredentials: true,
    });
  }

  /**
   * Creates a draft of a work item with the specified type, display name, and optional priority.
   *
   * @param {number} workItemTypeId - The ID of the work item type to create.
   * @param {string} displayName - The display name of the work item.
   * @param {number} [priority] - The priority of the work item. This parameter is optional.
   * @return {Observable<WorkItem>} An observable containing the created WorkItem instance.
   */
  public createWorkItemDraft(workItemTypeId: number, displayName: string, priority?: number): Observable<WorkItem> {
    const body: CreateWorkItemDto = {
      workItemTypeId: workItemTypeId,
      displayName,
      priority,
    };
    return this.httpClient.post<WorkItem>(`${this.environmentService.baseAdpHost}/work-item/draft`, body, {
      withCredentials: true,
    });
  }

  /**
   * Submits a draft work item to the server for processing.
   *
   * @param {string} workItemId - The unique identifier of the work item to be submitted.
   * @return {Observable<boolean>} An observable that resolves to true if the submission is successful, and false otherwise.
   */
  public submitWorkItemDraft(workItemId: UUID): Observable<boolean> {
    return this.httpClient.put<boolean>(
      `${this.environmentService.baseAdpHost}/work-item/details/${workItemId}/submit`,
      {
        withCredentials: true,
      },
    );
  }

  /**
   * Updates the specified work item with the given details.
   *
   * @param {UUID} workItemId - The unique identifier of the work item to update.
   * @return {Observable<WorkItem>} An observable containing the updated work item.
   */
  public updateWorkItem(workItemId: UUID): Observable<WorkItem> {
    const body = {
      workItemId,
      displayName: 'generated via frontend',
    };
    return this.httpClient.put<WorkItem>(
      `${this.environmentService.baseAdpHost}/work-item/details/${workItemId}`,
      body,
      {
        withCredentials: true,
      },
    );
  }

  /**
   * Retrieves the communication thread of an item.
   *
   * @param {UUID} workItemId - The UUID of the work item.
   * @returns {Observable<CommunicationThread>} - An Observable that emits the communication thread of the specified work item.
   */
  public getCommunicationThreadOfWorkItem(workItemId: UUID): Observable<CommunicationThread> {
    return this.httpClient
      .get<CommunicationThread>(
        `${this.environmentService.baseAdpHost}/communication/work-item/${workItemId}/thread?pageSize=50`,
        {
          withCredentials: true,
        },
      )
      .pipe(
        map((ele) => {
          ele.messages.sort((a, b) => compareByKey<Message>(a, b, { field: 'createdAt', order: 'DESC' }));
          return ele;
        }),
      );
  }

  /**
   * Deletes a data reference message by its associated identifiers.
   *
   * @param {UUID} threadId - The unique identifier of the thread.
   * @param {UUID} comMessageId - The unique identifier of the communication message.
   * @param {UUID} dataReferenceId - The unique identifier of the data reference.
   * @return {Observable<void>} - An observable that completes when the delete operation is finished.
   */
  public deleteDataReferenceMessage(threadId: UUID, comMessageId: UUID, dataReferenceId: UUID): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.environmentService.baseAdpHost}/communication/data/${threadId}/${comMessageId}/${dataReferenceId}`,
      {
        withCredentials: true,
      },
    );
  }

  /**
   * Retrieves the form field configuration for a specific work item type.
   *
   * @param {UUID} workItemId - The unique identifier of the work item.
   * @param {Language} [language=Language.Deutsch] - The language in which to retrieve the form field configuration.
   * @return {Observable<WorkItemFormFieldConfigResponse>} An observable containing the form field configuration response for the specified work item type.
   */
  public getFormFieldConfigForWorkItemType(
    workItemId: UUID,
    language: Language = Language.German,
  ): Observable<WorkItemFormFieldConfigResponse> {
    return this.httpClient.get<WorkItemFormFieldConfigResponse>(
      `${this.environmentService.baseAdpHost}/workflow/work-item/${workItemId}/current-step/form-definition?language=${language}`,
      {
        withCredentials: true,
      },
    );
  }

  /**
   * Submits the form data for a work item.
   *
   * @param {UUID} workItemId - The unique identifier of the work item.
   * @param {object} model - The data model representing the form data.
   * @param {string} formType - The type of the form being submitted.
   * @param {string} displayName - The display name for the form data.
   * @return {Observable<WorkItemFormData>} An observable containing the response form data for the submitted work item.
   */
  public submitWorkItemFormData(
    workItemId: UUID,
    model: object,
    formType: string,
    displayName: string,
  ): Observable<WorkItemFormData> {
    const body: CreateDocumentBodyDto = {
      displayName: displayName,
      documentBody: model,
      formType,
    };
    return this.httpClient.post<WorkItemFormData>(
      `${this.environmentService.baseAdpHost}/work-item/data/${workItemId}/form-data`,
      body,
      {
        withCredentials: true,
      },
    );
  }

  /**
   * Updates the form data of a specified work item.
   *
   * @param {UUID} workItemId - The unique identifier of the work item to update.
   * @param {object} model - The updated form data as an object.
   * @param {string} displayName - The display name associated with the form data.
   * @param {UUID} dataReferenceId - The unique identifier for the data reference.
   * @return {Observable<WorkItemFormData>} An observable containing the updated work item form data.
   */
  public updateWorkItemFormData(
    workItemId: UUID,
    model: object,
    displayName: string,
    dataReferenceId: UUID,
  ): Observable<WorkItemFormData> {
    return this.httpClient.put<WorkItemFormData>(
      `${this.environmentService.baseAdpHost}/work-item/data/${workItemId}/form-data`,
      {
        documentBody: model,
        displayName,
        dataReferenceId,
      },
      {
        withCredentials: true,
      },
    );
  }

  /**
   * Fetches the form data for a specific work item based on the provided work item ID and form type.
   *
   * @param {UUID} workItemId - The unique identifier of the work item.
   * @param {string} formType - The type of form to be retrieved.
   * @return {Observable<WorkItemFormData>} An observable that emits the form data for the specified work item.
   */
  public loadWorkItemFormData(workItemId: UUID, formType: string): Observable<WorkItemFormData> {
    return this.httpClient.get<WorkItemFormData>(
      `${this.environmentService.baseAdpHost}/work-item/data/${workItemId}/form-data?formType=${formType}`,
      {
        withCredentials: true,
      },
    );
  }

  /**
   * Creates a new message in the communication thread.
   *
   * @param {UUID} threadId - The ID of the communication thread.
   * @param {CreateMessageDto} message - The content of the message.
   * @returns {Observable<Message>} - The Observable that represents the creation of the message.
   */
  public createNewMessage(threadId: UUID, message: CreateMessageDto): Observable<Message> {
    return this.httpClient.post<Message>(
      `${this.environmentService.baseAdpHost}/communication/thread/${threadId}/message`,
      message,
      {
        withCredentials: true,
      },
    );
  }

  /**
   * Extracts the default sorting logic into a private method.
   */
  private applyDefaultSorting(searchParameters: SearchWorkItem): SearchWorkItem {
    if (!searchParameters.sortField && !searchParameters.sortOrder) {
      searchParameters.defaultOrderFor = 'AML_DESK';
    }
    return searchParameters;
  }

  /**
   * Retrieves work items based on provided search parameters.
   * If no sorting is provided, apply a default sort order.
   *
   * @param {SearchWorkItem} parametersObject - The query parameters for filtering work items.
   * @return {Observable<PaginatedResponse<WorkItem>>} - An observable with the paginated work items.
   */
  public getWorkItems(parametersObject: SearchWorkItem): Observable<PaginatedResponse<WorkItem>> {
    const searchParameters = this.applyDefaultSorting({ ...parametersObject });
    const parameters = this.buildHttpParams(searchParameters);
    return this.httpClient.get<PaginatedResponse<WorkItem>>(`${this.environmentService.baseAdpHost}/work-item/search`, {
      params: parameters,
      withCredentials: true,
    });
  }

  /**
   * Retrieves and exports work items as an XLSX (Excel) file based on provided search parameters.
   *
   * @param {SearchWorkItem} parametersObject - The search parameters for retrieving work items. If `sortField` and
   * `sortOrder` are not provided, a default sorting order for 'AML_DESK' is applied.
   *
   * @return {Observable<Blob>} An observable that emits the exported XLSX file as a binary large object (Blob).
   */
  public getExportWorkItemsXlsx(parametersObject: SearchWorkItem): Observable<Blob> {
    const searchParameters = this.applyDefaultSorting({ ...parametersObject });
    const parameters = this.buildHttpParams(searchParameters);
    return this.httpClient.get(`${this.environmentService.baseAdpHost}/work-item/search`, {
      params: parameters,
      responseType: 'blob',
      withCredentials: true,
    });
  }

  /**
   * Retrieves the available work item types.
   *
   * @return {Observable<WorkItemType[]>} - An Observable that emits an array of WorkItemType objects.
   */
  public getWorkItemTypes(): Observable<WorkItemType[]> {
    return this.httpClient.get<WorkItemType[]>(`${this.environmentService.baseAdpHost}/work-item/work-item-types`, {
      withCredentials: true,
    });
  }

  /**
   * Cancels the specified work item.
   *
   * @param {UUID} workItemId - The unique identifier of the work item to be canceled.
   * @return {Observable<WorkItem>} An observable emitting the canceled work item.
   */
  public cancelWorkItem(workItemId: UUID): Observable<WorkItem> {
    return this.httpClient.put<WorkItem>(
      `${this.environmentService.baseAdpHost}/work-item/details/${workItemId}/cancel`,
      {
        withCredentials: true,
      },
    );
  }

  /**
   * Fetches a list of DataReference objects associated with a specific work item identified by its unique ID.
   *
   * @param {UUID} workItemId - The unique identifier of the work item.
   * @return {Observable<DataReference[]>} An observable emitting an array of DataReference objects.
   */
  public getDataReferencesByWorkItemId(workItemId: UUID): Observable<DataReference[]> {
    return this.httpClient.get<DataReference[]>(
      `${this.environmentService.baseAdpHost}/work-item/data/${workItemId}/reference`,
      {
        withCredentials: true,
      },
    );
  }

  /**
   * Deletes the data reference for a given work item.
   *
   * @param {UUID} workItemId - The unique identifier of the work item.
   * @param {UUID} dataReferenceId - The unique identifier of the data reference to be deleted.
   * @return {Observable<void>} - An observable that completes when the deletion is successful.
   */
  public deleteDataReferenceWorkItem(workItemId: UUID, dataReferenceId: UUID): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.environmentService.baseAdpHost}/work-item/data/${workItemId}/${dataReferenceId}`,
      {
        withCredentials: true,
      },
    );
  }

  /**
   * Builds HttpParams from a given SearchWorkItem object.
   *
   * @param {SearchWorkItem} parametersObject - The object containing search parameters.
   * @return {HttpParams} The constructed HttpParams instance with the specified search parameters.
   */
  private buildHttpParams(parametersObject: SearchWorkItem): HttpParams {
    let parameters = new HttpParams();

    for (const key of Object.keys(parametersObject) as Array<keyof SearchWorkItem>) {
      const value = parametersObject[key];

      if (value !== null && value !== '') {
        parameters = Array.isArray(value)
          ? parameters.append(key, value.join(','))
          : parameters.append(key, String(value));
      }
    }

    return parameters;
  }
}
